import styled from 'styled-components'

export default styled.div`
  width: 60px;
  cursor: pointer;
  .camera-image {
    width: 100%;
    height: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .camera-status {
    width: 100%;
    text-align: center;
    font-family: 'Prompt-Medium', sans-serif;
    font-size: 14px;
    text-align: center;
    max-width: 70px;
    &.active {
      color: ${(props) => props.theme.onlineCamera};
    }
    &.in-active {
      color: ${(props) => props.theme.inactiveCamera};
    }
    &.offline {
      color: ${(props) => props.theme.offlineCamera};
    }
  }
`
