import React from 'react'
import PropTypes from 'prop-types'
import CameraActive from '../../asset/images/camera/camera-online.svg'
import CameraInActive from '../../asset/images/camera/camera-inactive.svg'
import CameraOffline from '../../asset/images/camera/camera-offline.svg'

import CameraMarkerStyled from './styledComponent'
import { CAMERA_ACTIVE, CAMERA_INACTIVE, CAMERA_OFFLINE } from '../../utils'

class CameraMarker extends React.PureComponent {
  getCameraImage() {
    const cameraSrcObject = {
      [CAMERA_ACTIVE]: CameraActive,
      [CAMERA_INACTIVE]: CameraInActive,
      [CAMERA_OFFLINE]: CameraOffline
    }
    return cameraSrcObject[this.props.status.toUpperCase()]
  }

  getCameraStatus() {
    const cameraClassNameObject = {
      [CAMERA_ACTIVE]: 'active',
      [CAMERA_INACTIVE]: 'in-active',
      [CAMERA_OFFLINE]: 'offline'
    }
    return cameraClassNameObject[this.props.status.toUpperCase()]
  }

  render() {
    return (
      <CameraMarkerStyled onClick={this.props.onClick}>
        <div className="camera-image">
          <img src={this.getCameraImage()} alt="" />
        </div>
        <div className={`camera-status prevent-text-overflow text-style ${this.getCameraStatus()}`}>{this.props.text}</div>
      </CameraMarkerStyled>
    )
  }
}

CameraMarker.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
}

export default CameraMarker
